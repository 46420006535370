import Vue from 'vue'
import VueRouter from 'vue-router'
const langArr = ['en','fr','es','us-es']
const lang = window.location.pathname.split('/')[1];
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
let routerUrl = ['about-us','shipping-info','faq','returns-refund','order-cancellation','project-tailor-made','size-guide','color-chart','contact-us','privacy-policy','terms-conditions','payment-methods','afterpay','clearpay','klarna','paypal-page','size/dress','size/flower-girl-dress','size/jewelry','size/lingerie','size/pajamas','size/rings','size/shoes','size/wrap','paypal','checkout-payment','checkout-payment/pay-button','ticket']
let titleArr = {
    'returns-refund':'Returns & Refunds',
    'faq':'Faqs',
}
let routes = [
    {
        path: '/',
        component: () => import('@/page/index'),
        meta: {title:""},
        hidden: true
    },
];
routerUrl.map((component,i)=>{
    let newTit = component.split('-').join(' ').replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
    routes.push({
        path:(langArr.indexOf(lang)>-1)? `/${lang}/${component}`:`/${component}`,
        component: () => import(`@/page/${component}`),
        meta: {
            title:titleArr.hasOwnProperty(component) ? titleArr[component] : newTit,
        }
    })
});


const router = new VueRouter({
    mode: 'history', //去掉url中的#
    base: '/',
    routes
})
router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
    if(to.meta.title){//判断是否有标题
        document.title = to.meta.title
    }
    next()  //执行进入路由，如果不写就不会进入目标页
})
export default router
